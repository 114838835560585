<script setup lang="ts">
import { ref } from 'vue';
import HasRole from '~/components/account/has-role.vue';
import { useOffersStore } from '~/stores/account/offers.store';

const props = defineProps({
	offer: { type: Object, required: true }
});

const isContentHidden = ref(true);
const isOptionsHidden = ref(true)

const { $toast } = useNuxtApp()

const offersStore = useOffersStore()

const deleteOffer = async () => {
	const offer_id = props.offer.id
	await offersStore.deleteOffer(offer_id)
		.then((res: any) => {
			$toast.addToast({
                title: res.data.message || "Offer Recorded Successfully",
                timeout: 5000,
                position: 'top-10',
			})
			isOptionsHidden.value = true;
			isContentHidden.value = true
			offersStore.fetchOffers()
		})
		.catch((err) => {
			const message = err?.message || 'Something Went Wrong Please Try Again'
			$toast.addToast({ title: message, color: "red" , position: 'bottom-10'})
			isOptionsHidden.value = true;
		})
} 

</script>

<template>
	<div class="flex justify-between relative w-full">
		<h2 class="text-lg" @click="() => isContentHidden = !isContentHidden">
			<i class="fa-solid fa-chevron-down me-3" ></i> 
			{{ offer.customer_name }}
		</h2>
		<div class="ms-auto mr-2 toggle-button" @click="() => isOptionsHidden = !isOptionsHidden">
			<i class="fa-solid fa-ellipsis-vertical fa-lg"></i>
		</div>
		<div
			class="toggle-content absolute right-0 top-3 mt-4 p-3 text-gray-600 bg-white shadow rounded divide-y divide-slate-200 z-50"
			:class="{ [isOptionsHidden ? 'hidden' : '']: true }"
		>
			<HasRole role_key="customer-offers:update">
				<NuxtLink :to="localePath({ name: 'account.offers.edit', params: { id: offer.id } })" class="py-2">
					<i class="fa-solid fa-pen me-2"></i>
					{{ $t('client.edit') }}
				</NuxtLink>
			</HasRole>
			<HasRole role_key="customer-offers:delete">
				<div @click="deleteOffer" class="py-2"> 
					<i class="fa fa-trash me-2"></i> 
					{{ $t('client.delete') }} 
				</div> 
			</HasRole>
		</div>	
	</div>
	<div class="bg-white mt-2" :class="{ [isContentHidden ? 'hidden' : '']: true }">
		<div class="flex items-center gap-4 text-sm mb-1">
			<div class="w-1/2">{{ $t('client.category') }}</div>
			<div class="font-medium">{{ offer.category_name }}</div>
		</div>
		<div class="flex items-center gap-4 text-sm mb-1">
			<div class="w-1/2">{{ $t('client.city') }}</div>
			<div class="font-medium">{{ offer.city_name }}</div>
		</div>
		<div class="flex items-center gap-4 text-sm mb-1">
			<div class="w-1/2">{{ $t('client.min_price') }}</div>
			<div class="font-medium">{{ offer.min_price }} {{ offer.currency_symbol }}</div>
		</div>
		<div class="flex items-center gap-4 text-sm mb-1">
			<div class="w-1/2">{{ $t('client.max_price') }}</div>
			<div class="font-medium">{{ offer.max_price }} {{ offer.currency_symbol }}</div>
		</div>
		<div class="flex items-center gap-4 text-sm mb-1">
			<div class="w-1/2">{{ $t('client.progress') }}</div>
			<div class="font-medium">{{ offer.progress }}</div>
		</div>
		<div class="flex items-center gap-4 text-sm mb-1">
			<div class="w-1/2">{{ $t('client.created_at') }}</div>
			<div class="font-medium">{{ new Date(offer.created_at).toLocaleDateString() }}</div>
		</div>
	</div>
	
	
</template>